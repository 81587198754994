<template>
  <div class="recruit-wantedly">
    <div v-if="Experienced" class="recruit-wantedly__experienced">
      <p>微経験/SESやIT経験のある方</p>
      <iframe
        target="_blank"
        frameborder="0"
        height="10%"
        name="wantedly_project_widget_1162835"
        scrolling="no"
        src="https://platform.wantedly.com/projects/1162835"
        style="border: none; max-width: 100%; min-width: 240px; width: 100%; max-height: 122px"
      ></iframe>
    </div>
    <div v-else-if="Inexperienced" class="recruit-wantedly__inexperienced">
      <div class="recruit-wantedly__experienced--web">
        <p>WEBエンジニア</p>
        <iframe
          target="_blank"
          frameborder="0"
          height="10%"
          name="wantedly_project_widget_1174890"
          scrolling="no"
          src="https://platform.wantedly.com/projects/1174890"
          style="border: none; max-width: 100%; min-width: 240px; width: 100%; max-height: 122px"
        />
      </div>
      <div class="recruit-wantedly__experienced--QA">
        <p>品質保証/QAエンジニア</p>
        <iframe
          target="_blank"
          frameborder="0"
          height="10%"
          name="wantedly_project_widget_1177635"
          scrolling="no"
          src="https://platform.wantedly.com/projects/1177635"
          style="border: none; max-width: 100%; min-width: 240px; width: 100%; max-height: 122px"
        ></iframe>
      </div>
      <div class="recruit-wantedly__experienced--infra">
        <p>インフラエンジニア</p>
        <iframe
          target="_blank"
          frameborder="0"
          height="10%"
          name="wantedly_project_widget_1184846"
          scrolling="no"
          src="https://platform.wantedly.com/projects/1184846"
          style="border: none; max-width: 100%; min-width: 240px; width: 100%; max-height: 122px"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecruitWantedly',
  components: {},
  props: {
    Inexperienced: {
      type: Boolean,
      default: false,
    },
    Experienced: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 6% auto 3%;
  font-weight: bold;
  font-size: min(4vw, 22px);

  @media screen and (min-width: 640px) {
    font-size: min(2vw, 22px);
  }
}
</style>
