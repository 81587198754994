<template>
  <div class="service">
    <div class="service__contents">
      <div class="service__contents__tag">
        <ContentsTag />
      </div>

      <div ref="massage" class="service__contents__ses">
        <div class="service__contents__ses--title">Service</div>
        <div class="service__contents__ses--title-bg" />
        <div class="service__contents__ses--sub-title">
          <div class="service__contents__ses--sub-title-label">システムエンジニアリングサービス</div>
          <div class="service__contents__ses--sub-title-border1" />
          <div class="service__contents__ses--sub-title-border2" />
        </div>

        <div class="service__contents__ses__main">
          <div class="service__contents__ses__main__text-area">
            <div class="service__contents__ses__main__text-area--text">
              <h4>
                ご要望に応じた技術を有する技術者をご提案いたします。<br />
                システム開発・保守・運用等に必要なリソースを確保したいというご要望に対応いたします。<br />
                <br />
                お客様先での常駐、リモート対応等、柔軟に対応可能です。<br />
                <br />
                ただの作業者ではなく、お客様の課題解決へ向け併走し、 総合的にご満足いただけるサービスをお約束致します。
              </h4>
            </div>
          </div>
          <div class="service__contents__ses__main--image">
            <v-img v-if="isScreenWidth640" src="../assets/img/service-sp.png" />
            <v-img v-else src="../assets/img/service-pc.png" />
          </div>
        </div>
      </div>

      <div class="service__contents__category">
        <div class="service__contents__category--title">Category</div>
        <div class="service__contents__category__main">
          <div class="service__contents__category__main-box web">
            <div class="service__contents__category__main-label">WEBシステム開発</div>
            <div class="service__contents__category__main-border" />
          </div>
          <div class="service__contents__category__main-box test">
            <div class="service__contents__category__main-label">検証・テスト</div>
            <div class="service__contents__category__main-border" />
          </div>
          <div class="service__contents__category__main-box infra">
            <div class="service__contents__category__main-label">サーバー構築・インフラ</div>
            <div class="service__contents__category__main-border" />
          </div>
        </div>
      </div>

      <div class="service__contents__information">
        <div v-if="isScreenWidth640">
          <div class="trapezoid-sp1" />
          <div class="trapezoid-sp2" />
        </div>
        <div class="service__contents__information__text">
          <div class="service__contents__information__text request1">ご依頼・ご相談</div>
          <div class="service__contents__information__text arrow1" />
          <div class="service__contents__information__text request2">
            ご依頼・ご相談はこちらよりお問い合わせください。
          </div>
          <div class="service__contents__information__text partner1">パートナー様募集</div>
          <div class="service__contents__information__text arrow2" />
          <div class="service__contents__information__text partner2">ご協業頂けるパートナー様を募集しております。</div>
        </div>
        <div class="service__contents__information--image">
          <v-img v-if="isScreenWidth640" src="../assets/img/service-human-s.png" />
          <v-img v-else src="../assets/img/service-human.png" />
        </div>
      </div>
      <div v-if="!isScreenWidth640" class="service__contents__bottom-area">
        <div class="trapezoid-pc1" />
        <div class="trapezoid-pc2" />
      </div>
    </div>
  </div>
</template>

<script>
import ContentsTag from '../parts/ContentsTag.vue';

export default {
  name: 'ServiceView',
  components: { ContentsTag },
  computed: {
    isScreenWidth640() {
      return this.$vuetify.breakpoint.width < 640;
    },
  },
};
</script>

<style lang="scss" scoped>
.service {
  &__contents {
    &__ses {
      &--title {
        font-size: min(5vw, 28px);
        padding: 40px;
        text-align: center;
        @media screen and (min-width: 960px) {
          font-size: min(5vw, 54px);
        }

        &-bg {
          position: absolute;
          width: 80%;
          height: 30vw;
          opacity: 0.2;
          margin-left: -40px;
          background-image: url('../assets/img/label-ses.png');
          background-size: contain;
          background-repeat: no-repeat;

          @media screen and (min-width: 640px) {
            width: 60%;
            height: 30vw;
            max-height: 440px;
          }
        }
      }

      &--sub-title {
        width: 90%;
        margin-right: 0;
        margin-left: auto;
        height: 100px;
        margin-top: 15vw;
        font-size: 1.2em;
        white-space: nowrap;

        @media screen and (min-width: 400px) {
          width: 80%;
        }

        @media screen and (min-width: 640px) {
          width: 60%;
          height: 150px;
          margin-top: 10vh;
          font-size: 1em;
        }

        @media screen and (min-width: 960px) {
          height: 200px;
          margin-top: 22vh;
        }

        &-label {
          font-size: 18px;
          text-align: left;

          @media screen and (min-width: 960px) {
            font-size: 24px;
          }
        }

        &-border1 {
          border: 1px solid;
          width: 99%;
          float: right;
          margin-top: 10px;
        }

        &-border2 {
          border: 1px solid;
          width: 84%;
          float: right;
          margin-top: 20px;
        }
      }

      &__main {
        @media screen and (min-width: 640px) {
          display: flex;
          flex-direction: row-reverse;
        }

        &--image {
          width: 90%;

          @media screen and (max-width: 640px) {
            margin-top: 20px;
          }

          @media screen and (min-width: 640px) {
            width: 50%;
            display: flex;
            align-items: center;
            margin-top: none;
          }
        }

        &__text-area {
          display: flex;
          justify-content: center;
          align-items: center;

          &--text {
            width: 65%;
            text-align: left;
            margin-left: 80px;
            font-size: 13px;

            @media screen and (min-width: 960px) {
              font-size: 17.5px;
            }

            @media screen and (min-width: 640px) {
              margin-left: 0;
            }
          }
        }
      }
    }

    &__category {
      margin-top: 20px;

      @media screen and (min-width: 640px) {
        margin-top: 80px;
      }

      &--title {
        text-align: center;
        font-size: 18.75px;

        @media screen and (min-width: 960px) {
          font-size: 50px;
        }
      }

      &__main {
        padding: 20px;

        @media screen and (min-width: 640px) {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;
        }

        &-box {
          width: 70%;
          height: 80px;
          text-align: center;
          border: 2px solid;

          @media screen and (max-width: 640px) {
            margin: 0 auto 20px;
          }
          @media screen and (min-width: 640px) {
            width: 220px;
            height: 100px;
          }

          &.web {
            color: rgb(200, 200, 200);
          }
          &.test {
            color: rgb(120, 120, 120);
          }
          &.infra {
            color: rgb(40, 40, 40);
          }
        }

        &-label {
          font-size: 0.9em;
          margin-top: 20px;
          margin-bottom: 16px;

          @media screen and (min-width: 640px) {
            margin-top: 30px;
          }
        }

        &-border {
          border-bottom: 1px solid;
          width: 70%;
          margin: 0 auto;
        }
      }
    }

    &__information {
      margin-top: 80px;
      position: relative;

      @media screen and (min-width: 640px) {
        display: flex;
        margin-bottom: 20px;
      }

      &__text {
        width: 95%;

        @media screen and (min-width: 640px) {
          width: 60%;
        }

        &.request1 {
          margin-top: 20px;
          width: 90%;
          text-align: right;
          font-size: 18px;
          z-index: 100;

          @media screen and (min-width: 960px) {
            font-size: 24px;
          }
        }
        &.request2 {
          width: 90%;
          text-align: right;
          font-size: 13px;
          white-space: nowrap;

          @media screen and (min-width: 960px) {
            font-size: 17.5px;
          }
        }
        &.arrow1 {
          // FIXME: 矢印を描画
          width: 90%;
          border: 1px solid;
        }
        &.arrow2 {
          // FIXME: 矢印を描画
          width: 80%;
          border: 1px solid;
        }
        &.partner1 {
          margin-top: 50px;
          width: 80%;
          text-align: right;
          font-size: 18px;

          @media screen and (min-width: 960px) {
            font-size: 24px;
          }
        }
        &.partner2 {
          width: 80%;
          text-align: right;
          font-size: 13px;
          white-space: nowrap;

          @media screen and (min-width: 960px) {
            font-size: 17.5px;
          }

          @media screen and (max-width: 640px) {
            margin-bottom: 50px;
          }
        }
      }

      &--image {
        @media screen and (min-width: 640px) {
          width: 40%;
          margin-right: 0;
          margin-left: auto;
        }
      }
    }
  }
}

.trapezoid-sp1 {
  position: absolute;
  top: -180px;
  width: 55%;
  height: 100px;
  border-top: 80px solid rgb(186, 186, 186);
  border-right: 20px solid transparent;
  box-sizing: border-box;
  opacity: 0.2;
}
.trapezoid-sp2 {
  position: absolute;
  top: -24px;
  width: 80%;
  height: 40px;
  border-top: 40px solid rgb(241, 241, 241);
  border-left: 30px solid transparent;
  box-sizing: border-box;
  right: 0;
  opacity: 0.3;
}

.trapezoid-pc1 {
  width: 55%;
  height: 100px;
  border-top: 140px solid rgb(186, 186, 186);
  border-right: 30px solid transparent;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.trapezoid-pc2 {
  width: 80%;
  height: 40px;
  border-top: 80px solid rgb(241, 241, 241);
  border-left: 40px solid transparent;
  box-sizing: border-box;
  margin-bottom: 80px;
  margin-right: 0;
  margin-left: auto;
}
</style>
