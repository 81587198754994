<template>
  <div class="contact">
    <div class="contact--tag"><ContentsTag /></div>
    <h1 class="contact--title">お問い合わせ</h1>
    <form v-on:submit.prevent="submit">
      <div class="contact__main">
        <div class="contact__main__title">貴社名</div>
        <div class="contact__main__val">
          <input type="text" name="company" class="contact__main__val--text" v-model="contact.company" />
        </div>
        <div class="contact__main__title">部署名</div>
        <div class="contact__main__val">
          <input type="text" name="department" class="contact__main__val--text" v-model="contact.department" />
        </div>
        <div class="contact__main__title">
          <p>ご担当者様名</p>

          <div class="contact__main__title--sub">性</div>
          <div class="contact__main__val">
            <input type="text" name="firstName" class="contact__main__val--text" v-model="contact.firstName" />
          </div>
          <div class="contact__main__title--sub">名</div>
          <div class="contact__main__val">
            <input type="text" name="lastName" class="contact__main__val--text" v-model="contact.lastName" />
          </div>
        </div>

        <div class="contact__main__title">
          <p>ご連絡先</p>
          <div class="contact__main__title--sub">TEL</div>
          <div class="contact__main__val">
            <input type="tel" name="tel" class="contact__main__val--text" v-model="contact.tel" />
          </div>
          <div class="contact__main__title--sub">MAIL</div>
          <div class="contact__main__val">
            <input type="email" name="mail" class="contact__main__val--text" v-model="contact.mail" />
          </div>
        </div>
        <div class="contact__main__title">ホームページURL</div>
        <div class="contact__main__val">
          <input type="url" name="url" class="contact__main__val--text" v-model="contact.url" />
        </div>

        <div class="contact__main__title">内容</div>
        <div class="contact__main__val">
          <label for="request">
            <input
              type="radio"
              name="category"
              value="ご依頼"
              id="request"
              class="c-input-radio"
              v-model="contact.category"
            />
            ご依頼
          </label>
          <label for="collaboration">
            <input
              type="radio"
              name="category"
              value="ご協業のお申込み"
              id="collaboration"
              class="c-input-radio"
              v-model="contact.category"
            />
            ご協業のお申込
          </label>
        </div>

        <div class="contact__main__title">お問い合わせ内容</div>
        <div class="contact__main__val">
          <textarea name="body" class="c-textarea" v-model="contact.body"></textarea>
        </div>

        <div class="contact">
          <div class="contact__main__title">サイトを訪問した経緯</div>
          <div class="contact__main__val">
            <label for="search">
              <input
                type="checkbox"
                name="route"
                value="検索"
                id="search"
                class="c-input-check"
                v-model="contact.route"
              />
              検索
            </label>
            <label for="sns">
              <input type="checkbox" name="route" value="SNS" id="sns" class="c-input-check" v-model="contact.route" />
              SNS
            </label>
            <label for="other">
              <input
                type="checkbox"
                name="route"
                value="紹介"
                id="other"
                class="c-input-check"
                v-model="contact.route"
              />
              紹介
            </label>
          </div>
        </div>
      </div>
      <button type="submit" class="c-btn">確認</button>
    </form>
  </div>
  <!-- </div>
  </div> -->
</template>

<script>
import ContentsTag from '../parts/ContentsTag.vue';

export default {
  name: 'ContactView',

  components: { ContentsTag },
  data() {
    return {
      contact: {
        name: '',
        category: null,
        mail: '',
        type: '',
        body: '',
        route: [],
      },
    };
  },
  methods: {
    submit() {
      // console.log(this.contact);
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  text-align: center;
  &__main {
    width: 70vw;
    margin: 0 auto;
    background: rgb(189, 189, 230);

    &__title {
      display: flex;
      width: 30vw;
      background: pink;
      &--sub {
        // flex-wrap: nowrap;
        background: rgb(243, 231, 233);
        width: 70vw;
      }
    }
    &__val {
      width: 60%;
      border: solid 1px;
      &--text {
      }
    }
  }
}
</style>
