<template>
  <div class="recruit-engage">
    <div v-if="Experienced" class="recruit-engage__experienced">
      <a
        href=""
        class="engage-recruit-widget"
        data-height="300"
        data-width="500"
        data-url="https://en-gage.net/m-b-kosmo/widget/?banner=1"
        target="_blank"
      />
    </div>
    <div v-else-if="Inexperienced" class="recruit-engage__ inexperienced">    <a
        href=""
        class="engage-recruit-widget"
        data-height="300"
        data-width="500"
        data-url="https://en-gage.net/m-b-kosmo/widget/?banner=1"
        target="_blank"
      /></div>
  </div>
</template>

<script>
export default {
  name: 'RecruitEngage',
  components: {},
  props: {
    Inexperienced: {
      type: Boolean,
      default: false,
    },
    Experienced: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.moreButton {
  margin: 0 auto;
  background: rgba(255, 255, 255, 0);
  color: rgb(122, 122, 122);
  border-radius: 1px;
  min-height: 20px;
  min-width: 120px;
  max-height: 60px;
  max-width: 170px;
  font-size: min(1.5vw, 22px);
}
</style>
