<template>
  <div class="news">
    <div class="news__contents">
      <div class="news__contents__tag">
        <ContentsTag />
      </div>
      <div ref="massage" class="news__contents__ses">
        <div class="news__contents__ses--title">news</div>
        <div class="news__contents__ses--title-bg" />
        <div class="news__contents__ses--sub-title">
          <div class="news__contents__ses--sub-title-label">システムエンジニアリングサービス</div>
          <div class="news__contents__ses--sub-title-border1" />
          <div class="news__contents__ses--sub-title-border2" />
        </div>
        <div class="news__contents__ses__main">
          <div class="news__contents__ses__main__text-area">
            <div class="news__contents__ses__main__text-area--text">
              <h4>
                ご要望に応じた技術を有する技術者をご提案いたします。<br />
                システム開発・保守・運用等に必要なリソースを確保したいというご要望に対応いたします。<br />
                <br />
                お客様先での常駐、リモート対応等、柔軟に対応可能です。<br />
                <br />
                ただの作業者ではなく、お客様の課題解決へ向け併走し、 総合的にご満足いただけるサービスをお約束致します。
              </h4>
            </div>
          </div>
          <div class="news__contents__ses__main--image"></div>
        </div>
      </div>

      <div class="news__contents__category">
        <div class="news__contents__category--title">Category</div>
      </div>

      <div class="news__contents__information"></div>
    </div>
  </div>
</template>

<script>
import ContentsTag from '../parts/ContentsTag.vue';

export default {
  name: 'NewsView',

  components: { ContentsTag },
};
</script>

<style lang="scss" scoped></style>
