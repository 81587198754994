import Vue from 'vue';
import Router from 'vue-router';
// import { createRouter } from 'vue-router';
import TopView from '../views/Top';
import CompanyView from '../views/Company';
import NewsView from '../views/News';
import PartnerView from '../views/Partner';
import RecruitView from '../views/Recruit';
import ServiceView from '../views/Service';
import SitePolicy from '../views/SitePolicy';
import PrivacyPolicy from '../views/PrivacyPolicy';
import ContactView from '../views/Contact';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Top',
      component: TopView,
    },
    {
      path: '/company',
      name: 'Company',
      component: CompanyView,
    },
    {
      path: '/news',
      name: 'News',
      component: NewsView,
    },
    {
      path: '/partner',
      name: 'Partner',
      component: PartnerView,
    },
    {
      path: '/recruit',
      name: 'Recruit',
      component: RecruitView,
    },
    {
      path: '/service',
      name: 'Service',
      component: ServiceView,
    },
    {
      path: '/site-policy',
      name: 'SitePolicy',
      component: SitePolicy,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
    },
    {
      path: '/contact',
      name: 'Contact',
      component: ContactView,
    },
  ],
});
