<template>
  <div class="contents-tag">{{ routeChangetext() }}</div>
</template>

<script>
export default {
  name: 'ContetsTag',
  components: {},
  computed: {
    getRoute() {
      return this.$route.path;
    },
  },
  methods: {
    routeChangetext() {
      let route = this.getRoute;
      if (route === '/') {
        return;
      } else {
        let str = route.replace('/', '');
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contents-tag {
  position: relative;
  margin-top: -19%;
  margin-bottom: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40vw;
  height: 8vw;
  max-height: 50px;
  padding-right: 1vw;
  border-radius: 1px;
  background: #343f53;
  color: #fff;
  font-size: min(4.5vw, 19px);
  font-weight: bold;
  box-shadow: 1px 2px 3px rgba(13, 17, 71, 0.4);

  @media screen and (min-width: 640px) {
    margin-top: -15.5%;
    margin-bottom: 8%;
  }

  @media screen and (min-width: 960px) {
    width: 25vw;
    height: 4vw;
    margin-top: -12%;
    font-size: min(2vw, 19px);
  }
}
</style>
