<template>
  <div class="company">
    <div class="company__contents">
      <div class="company__contents__tag">
        <ContentsTag />
      </div>
      <div class="company__contents__button">
        <v-btn
          @click="onClickScroll('message'), onClickMassage()"
          class="company__contents__button--message"
          outlined
          height="5vw"
          width="20vw"
          min-width="150px"
          min-height="30px"
          >Message</v-btn
        >
        <v-btn
          @click="onClickScroll('company-profile'), onClickCompany()"
          class="company__contents__button--profile"
          outlined
          height="5vw"
          width="20vw"
          min-width="150px"
          min-height="30px"
          >Company Profile</v-btn
        >
      </div>
      <div ref="message" class="company__contents__message" v-if="(ButtonFlag && isScreenSmAndDown) || isScreenMdAndUp">
        <div class="company__contents__message--title">Message</div>
        <div class="company__contents__message__main">
          <div class="company__contents__message__main--image">
            <v-img src="../assets/img/company-top-massage.png" />
          </div>
          <!-- <div class="company__contents__message__main--band" v-if="isScreenMdAndUp"> -->
          <div class="company__contents__message__main--band">技術力を通して、お客様のビジネス発展に貢献致します。</div>
          <div class="company__contents__message__main__text-area">
            <div class="company__contents__message__main__text-area--text">
              <p>
                メディウスバンドコスモロギーは、システム開発/構築、運用、第三者検証等幅広い技術を以ってお客様のニーズに応えるサービスをご提供するべく、2022年7月に設立致しました。<br />
                「技術力」「ヒューマンスキル」「併走力」を兼ね備えた総合品質の高い技術者がサービスをご提供致します。<br />
                <br />
                常に移り変わるIT業界でシステム開発・第三者検証等の技術分野によるご協力を通して、お客様のビジネス発展をご支援していくことを使命と考えており、技術の向上だけではなく、お客様に寄り添ってサービスを提供できる/併走できる人材育成に取り組んでおります。<br />
                <br />
                技術品質・ヒューマンスキルも含めご満足いただけるサービスをご提供できるよう一同尽力してまいります。<br />
                <br />
                今後とも変わらぬご支援を賜りますようお願い申し上げます。
              </p>
            </div>
            <div class="company__contents__message__main__text-area--name"><p>代表取締役 高野 優</p></div>
          </div>
        </div>
      </div>
      <div
        ref="company-profile"
        class="company__contents__profile"
        v-if="(!ButtonFlag && isScreenSmAndDown) || isScreenMdAndUp"
      >
        <div class="company__contents__profile--title">Company Profile</div>
        <div class="company__contents__profile__main">
          <div class="company__contents__profile__main__text-area">
            <div class="company__contents__profile__main__text-area--subject">
              <p>会社名</p>
              <p>設立</p>
              <p>資本金</p>
              <p>代表者</p>
              <p>所在地</p>
              <p>事業内容</p>
              <!-- <p />
              <p /> -->
            </div>
            <div class="company__contents__profile__main__text-area--text">
              <p>メディウスバンドコスモロギー株式会社</p>
              <p>2022年7月</p>
              <p>1,000,000円</p>
              <p>高野 優</p>
              <p>〒160-0022<br />東京都新宿区新宿１丁目３６番２号 新宿第七葉山ビル３Ｆ</p>
              <p>
                ITサービス、システムエンジニアリングサービス事業<br />ゲーム、アプリケーション、ソフトウェアの企画・開発・運用
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentsTag from '../parts/ContentsTag.vue';

export default {
  name: 'CompanyView',
  data: () => ({
    //
    ButtonFlag: true,
  }),

  components: { ContentsTag },
  computed: {
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isScreenMdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.getScroll);
  },
  methods: {
    onClickScroll(refName) {
      if (this.isScreenMdAndUp) {
        const el = this.$refs[refName];
        el.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    },
    onClickMassage() {
      this.ButtonFlag = true;
    },
    onClickCompany() {
      this.ButtonFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.company {
  &__contents {
    &__button {
      margin: 10% auto;
      display: flex;
      gap: 3%;
      justify-content: center;
      align-items: center;

      &--message,
      &--profile {
        text-transform: none;
        letter-spacing: 0.1px;
        background: rgba(255, 255, 255, 0);
        color: rgb(122, 122, 122);
        border-radius: 1px;
        font-size: min(22vw, 15px);

        @media screen and (min-width: 640px) {
          max-height: 40px;
          max-width: 180px;
        }

        @media screen and (min-width: 960px) {
          max-height: 60px;
          max-width: 270px;
          font-size: min(22vw, 25px);
        }
      }
    }
    &__message {
      font-weight: bold;

      &--title {
        text-align: center;
        font-size: min(5vw, 28px);
        margin-bottom: 10%;

        @media screen and (min-width: 640px) {
          margin-bottom: 0;
        }

        @media screen and (min-width: 960px) {
          font-size: min(5vw, 54px);
          margin-bottom: 7%;
        }
      }
      &__main {
        display: flex;
        flex-wrap: wrap;

        &--image {
          width: 100%;
          position: relative;
          z-index: 1;
          bottom: -55px;
          margin: -10% 0 auto auto;

          @media screen and (min-width: 640px) {
            max-width: 700px;
            margin: 0 auto;
          }

          @media screen and (min-width: 960px) {
            max-width: 1000px;
            width: 45vw;
            margin: 5% 0 auto auto;
            box-shadow: 3px 3px 1px rgba(10, 16, 89, 0.6);
            z-index: 2;
            bottom: 0;
          }
        }

        &--band {
          position: relative;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: 6% auto 0 0;
          width: 96vw;
          height: 11vw;
          font-size: min(3.3vw, 28px);
          color: #fff;
          background: #3f4951;
          box-shadow: 1px 2px 2px rgba(10, 16, 89, 0.4);
          padding-right: 1%;
          padding-left: 2%;
          z-index: 4;

          @media screen and (min-width: 640px) {
            margin: 3% auto 0 0;
            width: 80vw;
            height: 7vw;
            font-size: min(2.5vw, 28px);
          }

          @media screen and (min-width: 960px) {
            width: 65vw;
            margin: -30% auto 0 0;
            max-height: 80px;
            font-size: min(2vw, 32px);
          }
        }

        &__text-area {
          width: 100%;
          margin: -8.5% auto 0;
          background: linear-gradient(
            165deg,
            transparent 13%,
            rgba(255, 255, 255, 0) 0% 30%,
            rgba(0, 43, 70, 0.85) 30% 45%,
            rgba(0, 43, 70, 0.9) 45% 100%
          );

          @media screen and (min-width: 640px) {
            margin: -4% auto 0;
          }

          @media screen and (min-width: 960px) {
            width: 85%;
            max-width: 1800px;
            min-height: 250px;
            background: #f0f0f0;
            box-shadow: -1px 2px 1px rgba(237, 238, 240, 0.8);
            margin: -25% 0 auto auto;
            z-index: 1;
          }

          &--text {
            background: white;
            width: 85%;
            margin: -3% auto 0%;
            padding: 5% 4% 2% 4%;
            z-index: 2;

            @media screen and (min-width: 640px) {
              max-width: 600px;
              margin: -5% auto 0;
              padding: 2% 4% 2% 4%;
            }

            @media screen and (min-width: 960px) {
              // margin: 10% 10% 0% 10%;
              max-width: 900px;
              padding: 0% 3% 2% 1%;
              width: 60%;
              background: none;
              margin: 0 40vw 0 auto;
            }

            p {
              margin: 8% 8% 0 8%;
              overflow-wrap: break-word;
              font-size: small;
              padding-top: 8%;
              padding-bottom: 8%;

              @media screen and (min-width: 640px) {
                margin: 8% auto 5%;
              }

              @media screen and (min-width: 960px) {
                margin: 8% 50vw 5% 5%;
                overflow-wrap: break-word;
                width: 75%;
                font-size: min(1.2vw, 20px);
                padding-top: 0;
                padding-bottom: 0;
              }
            }
          }

          &--name {
            width: 85%;
            padding: 5% 0 2% 50%;
            margin: 0% auto 20%;
            background: white;
            white-space: nowrap;
            font-size: x-large;

            @media screen and (min-width: 640px) {
              max-width: 600px;
            }

            @media screen and (min-width: 960px) {
              width: 50%;
              background: none;
              margin: 0;
              padding: 0 0 3% 30%;
            }
          }
        }
      }
    }

    &__profile {
      margin: 10% auto 0;
      font-weight: bold;

      &--title {
        text-align: center;
        font-size: min(5vw, 28px);

        @media screen and (min-width: 640px) {
          margin-bottom: 0;
        }

        @media screen and (min-width: 960px) {
          font-size: min(5vw, 54px);
          margin-bottom: 7%;
        }
      }

      &__main {
        width: 100%;
        background-image: linear-gradient(180deg, transparent 35%, rgba(149, 149, 149, 0.4) 35%);
        padding: 0 0 15% 0;

        &__text-area {
          font-size: clamp(14px, 1.5vw, 14px);
          margin: 10% auto 0;
          display: flex;
          justify-content: center;
          width: 65%;
          max-width: 700px;
          min-width: 310px;
          background: #fff;
          padding: 0 4%;

          @media screen and (min-width: 640px) {
            min-width: 420px;
          }

          @media screen and (min-width: 960px) {
            padding: 0 5%;
            min-width: 485px;
          }

          &--subject {
            margin: 0 auto;
            width: 20%;
            min-width: 60px;
            height: 80%;

            :nth-of-type(5) {
              padding-bottom: 55px;
            }

            @media screen and (min-width: 960px) {
              width: 25%;
            }
          }

          &--text {
            margin: 0 auto;
            min-width: 400px;
            width: 80%;
            height: 80%;

            :nth-of-type(6) {
              letter-spacing: -1px;
            }

            @media screen and (min-width: 960px) {
              width: 75%;
            }
          }

          p {
            line-height: 40px;
            padding-bottom: 15px;
            border-bottom: solid 1px;

            &:last-child {
              border: none;
            }
          }
        }
      }
    }
  }
}
</style>
