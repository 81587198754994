<template>
  <div class="recruit">
    <div class="recruit__contents">
      <div class="recruit__contents__tag">
        <ContentsTag />
      </div>
      <div class="recruit__contents__button">
        <v-btn
          @click="onClickScroll('message'), onClickMassage()"
          class="recruit__contents__button--message"
          outlined
          height="5vw"
          width="20vw"
          min-width="150px"
          min-height="30px"
          >Message</v-btn
        >
        <v-btn
          @click="onClickScroll('recruit'), onClickCompany()"
          class="recruit__contents__button--profile"
          outlined
          height="5vw"
          width="20vw"
          min-width="150px"
          min-height="30px"
          >採用情報</v-btn
        >
      </div>
      <div ref="message" class="recruit__contents__message" v-if="(ButtonFlag && isScreenSmAndDown) || isScreenMdAndUp">
        <div class="recruit__contents__message--title">Message</div>
        <div class="recruit__contents__message__main">
          <div class="recruit__contents__message__main--image">
            <v-img src="../assets/img/top-recruit.png" />
          </div>

          <div class="recruit__contents__message__main__text-area">
            <div class="recruit__contents__message__main__text-area--text">
              <p>
                メディウスバンドコスモロギーは、システム開発/構築/運用、第三者検証/品質保証、インフラ構築/運用/保守等、各技術分野で顧客のビジネス発展をサポートするエンジニアを募集しております。<br /><br />
                一定以上の技術力やご経験を積んで頂ければ、案件を選べることがSESのメリットの一つ。 <br />
                SESのメリットを最大限活かし、数あるプロジェクトの中から、社員各々の希望する技術分野/キャリア像の実現をサポート。<br />
                「案件を選べる」技術や経験を身につけられるよう育成致します。
                <br /><br />
                経験者/未経験者問わず、「技術を身につけて成したい野望がある」という方を歓迎いたします。
                <br />
              </p>
            </div>
            <!-- <div class="recruit__contents__message__main__text-area--name"><p>高野 優</p></div> -->
          </div>
        </div>
      </div>
      <div
        ref="recruit"
        class="recruit__contents__recruit-area"
        v-if="(!ButtonFlag && isScreenSmAndDown) || isScreenMdAndUp"
      >
        <div class="recruit__contents__recruit-area--title">採用情報</div>
        <div class="recruit__contents__recruit-area--company">
          <p>採用ページ</p>
          <div class="recruit__contents__recruit-area--company--button">
            <v-btn
              class="recruit__contents__recruit-area--company--button--wantedly"
              outlined
              height="5vw"
              width="20vw"
              min-width="150px"
              min-height="30px"
              href="https://www.wantedly.com/companies/company_5048172"
              target="_blank"
              >Wantedly</v-btn
            >
            <v-btn
              class="recruit__contents__recruit-area--company--button--engage"
              outlined
              height="5vw"
              width="20vw"
              min-width="150px"
              min-height="30px"
              href="https://en-gage.net/m-b-kosmo/"
              target="_blank"
              >engage</v-btn
            >
          </div>
        </div>
        <div class="recruit__contents__recruit-area__main">
          <div class="recruit__contents__recruit-area__main--subject">
            <div @click="onClickInexperienced()">未経験者</div>
            <div @click="onClickExperienced()">経験者</div>
          </div>
          <div class="recruit__contents__recruit-area__main--text">
            <div class="recruit__contents__recruit-area__main--text--wantedly">
              <RecruitWantedly :Experienced="Experienced" :Inexperienced="Inexperienced" />
            </div>
            <div class="recrui--contents__recruit-area__main--text--engage">
              <RecruitEngage :Experienced="Experienced" :Inexperienced="Inexperienced" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentsTag from '../parts/ContentsTag.vue';
import RecruitWantedly from '../parts/Recruit-wantedly.vue';
import RecruitEngage from '../parts/Recruit-engage.vue';

export default {
  name: 'RecruitView',
  data: () => ({
    //
    ButtonFlag: true,
    Experienced: false,
    Inexperienced: true,
  }),

  components: { ContentsTag, RecruitWantedly, RecruitEngage },
  computed: {
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isScreenMdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.getScroll);
  },
  methods: {
    onClickScroll(refName) {
      if (this.isScreenMdAndUp) {
        const el = this.$refs[refName];
        el.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    },
    onClickMassage() {
      this.ButtonFlag = true;
    },
    onClickCompany() {
      this.ButtonFlag = false;
    },
    onClickInexperienced() {
      (this.Inexperienced = true), (this.Experienced = false);
    },
    onClickExperienced() {
      (this.Inexperienced = false), (this.Experienced = true);
    },
  },
};
</script>

<style lang="scss" scoped>
.recruit {
  &__contents {
    &__button {
      margin: 10% auto;
      display: flex;
      gap: 3%;
      justify-content: center;
      align-items: center;

      &--message,
      &--profile {
        text-transform: none;
        letter-spacing: 0.1px;
        background: rgba(255, 255, 255, 0);
        color: rgb(122, 122, 122);
        border-radius: 1px;
        font-size: min(22vw, 15px);

        @media screen and (min-width: 640px) {
          max-height: 40px;
          max-width: 180px;
        }

        @media screen and (min-width: 960px) {
          max-height: 60px;
          max-width: 270px;
          font-size: min(22vw, 25px);
        }
      }
    }
    &__message {
      font-weight: bold;
      margin: 0 auto 5%;
      @media screen and (min-width: 960px) {
        width: 70%;
      }

      &--title {
        text-align: center;
        font-size: min(5vw, 28px);
        margin-bottom: 10%;

        @media screen and (min-width: 640px) {
          margin-bottom: 0;
        }

        @media screen and (min-width: 960px) {
          margin: 0 auto;
          font-size: min(5vw, 54px);
        }
      }
      &__main {
        margin: 0 auto;
        max-width: 1800px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        &--image {
          width: 60%;
          opacity: 0.3;

          @media screen and (min-width: 640px) {
          }

          @media screen and (min-width: 960px) {
            margin: auto;
          }
        }

        &__text-area {
          width: 55%;
          margin: auto 0 auto -20%;
          z-index: 1;
          font-size: min(2vw, 22px);

          @media screen and (min-width: 640px) {
          }

          @media screen and (min-width: 960px) {
            margin: auto 0 auto -20%;
            width: 60%;
          }

          &--text {
            width: 80%;
            font-size: min(1.4vw, 100px);
            overflow-wrap: break-word;
          }
        }
      }
    }

    &__recruit-area {
      margin: 10% auto 0;
      font-weight: bold;
      text-align: center;
      background: rgb(221, 230, 236);

      &--title {
        margin: 0 auto;
        padding-top: 5%;
        text-align: center;
        font-size: min(5vw, 28px);

        @media screen and (min-width: 640px) {
          margin-bottom: 0;
        }

        @media screen and (min-width: 960px) {
          font-size: min(5vw, 54px);
          margin-bottom: 1%;
        }
      }

      &--company {
        text-align: center;
        margin: auto;
        padding: 3% 0 3% 0;
        p {
          display: none;
          font-weight: bold;
          @media screen and (min-width: 960px) {
            font-size: min(5vw, 28px);
          }
        }

        &--button {
          margin: 5% auto;
          @media screen and (min-width: 960px) {
            margin: 0 auto;
          }
          &--wantedly,
          &--engage {
            // gap: 10vw;
            margin: 0 1%;
            text-transform: none;
            letter-spacing: 0.1px;
            background: rgba(255, 255, 255, 0);
            color: rgb(122, 122, 122);
            border-radius: 1px;
            font-size: min(22vw, 15px);

            @media screen and (min-width: 640px) {
              max-height: 40px;
              max-width: 180px;
            }

            @media screen and (min-width: 960px) {
              max-height: 60px;
              max-width: 270px;
              font-size: min(22vw, 25px);
            }
          }
        }
      }

      &__main {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: center;
        @media screen and (min-width: 960px) {
          gap: 30px;
        }
        &--subject {
          padding: 5% 0 0 0;
          width: 20%;
          font-size: min(3vw, 23px);
          text-align: center;
          @media screen and (min-width: 640px) {
            font-size: min(2vw, 23px);
          }
          @media screen and (min-width: 960px) {
            min-height: 500px;
            margin: 0 0 0 auto;
          }
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 3% auto;
            height: 10vw;
            border-bottom: solid rgba(122, 122, 122, 0.4);
            @media screen and (min-width: 640px) {
              height: 5vw;
              // margin: 0 auto;
            }
            @media screen and (min-width: 960px) {
              margin: auto;
              height: 10%;
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }

        &--text {
          margin: 5% 0 5%;
          width: 55%;
          text-align: left;

          @media screen and (min-width: 960px) {
            margin: 1% auto 5% 0;
            max-width: 600px;
          }

          &--wantedly,
          &--engage {
            margin: 0 auto 0 0;
            height: 70%;
            width: 85%;

            @media screen and (min-width: 640px) {
              margin: 0 auto;
            }
            @media screen and (min-width: 960px) {
            }
          }
        }
      }
    }
  }
}
</style>
