<template>
  <div>
    <v-btn class="moreButton" outlined height="3.5vw" width="10.5vw">More</v-btn>
  </div>
</template>

<script>
export default {
  name: 'MoreButton',
  components: {},
};
</script>

<style lang="scss" scoped>
.moreButton {
  margin: 0 auto;
  background: rgba(255, 255, 255, 0);
  color: rgb(122, 122, 122);
  border-radius: 1px;
  min-height: 20px;
  min-width: 120px;
  max-height: 60px;
  max-width: 170px;
  font-size: min(1.5vw, 22px);
}
</style>
