<template>
  <div class="partner">
    <div class="partner__contents">
      <div class="partner__contents__text">
        <div class="partner__contents__text__title">Partner</div>
        <div class="partner__contents__text__massage">
          継続的なご協力関係を築ける<br class="br-sp" />協業パートナー様を募集しております。
        </div>
        <div @click="onClickMoreButton()" class="partner__contents__text__more-button">
          <MoreButton class="partner__contents__text__moreButton" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreButton from './MoreButton.vue';

export default {
  name: 'PartnerParts',

  components: {
    MoreButton,
  },
  methods: {
    onClickMoreButton() {
      this.$router.push('/partner');
    },
  },
};
</script>

<style lang="scss" scoped>
.partner {
  background: #eaeef2;
  box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.2);

  &__contents {
    padding: 1%;
    width: 60%;
    margin: 10% auto;

    @media screen and (min-width: 960px) {
      margin: 5% auto;
    }

    &__text {
      width: 100%;
      height: 100%;
      text-align: center;

      &__title {
        margin: 5% auto;
        font-size: min(5vw, 67px);
      }

      &__massage {
        margin: 7% auto 15%;
        font-size: min(2vw, 22px);
        white-space: nowrap;

        @media screen and (min-width: 604px) {
          .br-sp {
            display: none;
          }
        }

        @media screen and (min-width: 960px) {
          margin: 10% auto 5%;
          font-size: min(1.6vw, 100px);
        }
      }

      &__moreButton {
        margin: 5% auto;
      }
    }
  }
}
</style>
