<template>
  <div class="company">
    <div class="company__contents">
      <div class="company__contents__text">
        <div class="company__contents__text__title">Company</div>
        <div class="company__contents__text__massage">Mehrdius Band Kosmologie</div>
        <div @click="onClickMoreButton()" class="company__contents__text__more-button">
          <MoreButton class="company__contents__text__moreButton" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreButton from './MoreButton.vue';

export default {
  name: 'CompanyParts',

  components: {
    MoreButton,
  },
  methods: {
    onClickMoreButton() {
      this.$router.push('/company');
    },
  },
};
</script>

<style lang="scss" scoped>
.company {
  background: url(../assets/img/top-company.png) no-repeat;
  background-size: cover;
  height: 100%;

  &__contents {
    width: 100%;

    @media screen and (min-width: 960px) {
    }

    &__text {
      width: 50%;
      height: 40vw;
      margin: 15% auto 0;
      text-align: center;
      &__title {
        font-size: min(5vw, 67px);
      }
      &__massage {
        margin: 7% auto 15%;
        font-size: min(2vw, 22px);
        white-space: nowrap;
        @media screen and (min-width: 960px) {
          margin: 10% auto 15%;
          font-size: min(1.4vw, 100px);
        }
      }
      &__moreButton {
        margin: auto;
      }
    }
  }
}
</style>
