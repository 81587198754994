// 各ページ内ヘッダー画像のコンポーネント
<template>
  <div class="header-image">
    <div class="header-image__contents">
      <v-img :class="getClass()" :src="getImage" :max-height="imageSize" position="0% 40%">
        <div v-if="isTop" class="header-image__contents--top-text">技術で繋がり、<br />技術で寄り添う。</div>
      </v-img>
      <!--本当はポジションを変数でやりたい <v-img :class="getClass()" :src="getImage" :max-height="imageSize" position="imagePosition()" /> -->
      <!-- <v-img src="../assets/img/top-recruit.png" /> -->
      <!-- getimageです{{firename() }} -->
      <!-- <v-img :src="getImage" /> -->
    </div>
    <div class="header-image__contents--sp-background">
      <div class="header-image__contents--sp-background-first" :class="getClass()" />
      <div class="header-image__contents--sp-background-second" :class="getClassSpTop()" />
      <div class="header-image__contents--sp-background-sard" :class="getClassSpTop()" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderImage',

  components: {},
  props: {
    getRoute: {
      // type: [],
      default: '/',
    },
  },
  data: () => ({
    route: '',
    routeImage: '',
  }),

  computed: {
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    // 画像のアクセス先変更用
    getImage() {
      return require('../assets/img/header_' + this.routeChangetext() + '.png');
    },
    //
    imageSize() {
      if (this.getRoute === '/') {
        return '625px';
      } else {
        return '350px';
      }
    },
    // TOPのヘッダー画像にのみ、キャッチコピーのテキストをv-if表示用
    isTop() {
      return this.getRoute === '/';
    },
  },
  methods: {
    //取得したルートにより、クラスを切り替え、
    // そのクラス毎にヘッダー画像を切り替えております。
    // getClassHeaderImage() {
    //   let route = this.getRoute;
    //   var hogehoge = [];
    //   hogehoge =  [
    //     { top: route === '/' },
    //     { company: route === '/company' },
    //     { service: route === '/service' },
    //     { partner: route === '/partner' },
    //     { news: route === '/news' },
    //     { recruit: route === '/recruit' },
    //     { privacy: route === '/pricacy' },
    //     { site: route === '/site' },
    //   ];
    //   console.log(hogehoge[0]);
    //   console.log(hogehoge.filter);
    //   return hogehoge.filter((d) => (d))
    // },
    // ルートによりクラス切り替え用
    getClass() {
      let route = this.getRoute;
      return [
        { top: route === '/' },
        { company: route === '/company' },
        { service: route === '/service' },
        { partner: route === '/partner' },
        { news: route === '/news' },
        { recruit: route === '/recruit' },
        { privacy: route === '/pricacy-policy' },
        { site: route === '/site-policy' },
      ];
    },
    // ルートにより画像のポジション位置切り替え用
    imagePosition() {
      let route = this.getRoute;
      let hairetu = [
        { '0% 0%': route === '/' },
        { '30% 30%': route === '/company' },
        { '40% 40%': route === '/service' },
        { '60% 60%': route === '/partner' },
        { '70% 70%': route === '/news' },
        { '90% 90%': route === '/recruit' },
        { privacy: route === '/pricacy-policy' },
        { site: route === '/site-policy' },
      ];
      let kobetu = hairetu.indexOf((n) => n);
      return kobetu;
    },
    // ルートにより、imgファイル名指定用のテキスト抽出用
    routeChangetext() {
      let route = this.getRoute;
      if (route === '/') {
        return 'top';
      } else {
        return route.replace('/', '');
      }
    },
    getClassSpTop() {
      return [{ 'sp-top': this.getRoute === '/' && this.isScreenSmAndDown }];
    },
  },
};
</script>

<style lang="scss" scoped>
$imageWidth: 120%;
$imageHeight: 300px;

.header-image {
  // margin: 0 auto 25%;

  @media screen and (min-width: 640px) {
    margin: 0 auto;
  }

  &__contents {
    margin: 0 auto auto -100%;
    .top {
      width: 200%;
    }
    @media screen and (min-width: 640px) {
      margin: 0 auto;
      width: 100%;
    }

    v-img {
      width: 20%;
      height: 100vh;
    }

    &--top-text {
      width: 100%;
      color: #fff;
      font-weight: bold;
      text-align: right;
      margin: 0 auto 0;
      font-size: min(10vw, 50px);
      line-height: 5.5rem;
      padding-top: 12rem;
      padding-right: 1vw;

      @media screen and (min-width: 640px) {
        width: 50%;
        text-align: left;
        margin: 0 auto;
        font-size: min(5vw, 55px);
        line-height: 6.5rem;
        padding-top: 5.5rem;
      }
      @media screen and (min-width: 640px) {
        padding-top: 12rem;
      }
    }

    &--sp-background {
      margin: -20% auto 0;

      @media screen and (min-width: 640px) {
        margin: -13% auto 0;
      }

      @media screen and (min-width: 960px) {
        margin: -8% auto 0;
      }

      .top {
        background-color: rgba(12, 12, 78, 0.7);
        width: 100vw;
      }

      &-first {
        margin: 0 auto;
        width: 100vw;
        height: 20vw;
        clip-path: polygon(90% 0%, 0% 100%, 100% 100%);
        background-color: rgba(255, 255, 255, 0);

        @media screen and (min-width: 640px) {
          width: 100vw;
          height: 13vw;
          clip-path: polygon(16% 0%, 0% 100%, 100% 100%);
        }

        @media screen and (min-width: 960px) {
          height: 8vw;
          clip-path: polygon(30% 0%, 0% 100%, 85% 100%);
        }
      }

      &-second {
        position: absolute;
        margin: 0 auto;
        width: 100vw;
        height: 20vw;
        clip-path: polygon(50% 0%, 100% 0%, 100% 100%);
        background-color: rgba(219, 219, 219, 0.6);

        @media screen and (min-width: 640px) {
          height: 8vw;
          clip-path: polygon(90% 0%, 0% 100%, 0% 0%);
        }

        @media screen and (min-width: 960px) {
          height: 6vw;
          clip-path: polygon(85% 0%, 15% 100%, 0% 0%);
        }
        &.sp-top {
          display: none;
        }
      }

      &-sard {
        margin: 0 auto 0;
        transform: rotate(0deg);
        width: 100vw;
        height: 15vw;
        clip-path: polygon(90% 0%, 0% 100%, 0% 0%);
        background-color: rgba(194, 212, 222, 0.3);

        @media screen and (min-width: 640px) {
          width: 100vw;
          height: 12vw;
          clip-path: polygon(65% 0%, 100% 0%, 100% 100%);
        }

        @media screen and (min-width: 960px) {
          height: 10vw;
          clip-path: polygon(55% 0%, 100% 0%, 100% 100%);
        }
        &.sp-top {
          display: none;
        }
      }
    }
  }
}
.top {
  // margin: 50% auto;
  // height: $imageHeight;
  object-fit: none;
  object-position: 90% 70%;
  width: $imageWidth;
  // object-fit: cover;

  // background-image:;
}
.company {
  // object-position: 0% 90%; imgタグには効くのにv-imgタグには効かない
}
.service {
}
.partner {
}
.news {
}
.recruit {
}
.privacy {
}
.site {
}
</style>
