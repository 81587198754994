<template>
  <div class="service">
    <div class="service__contents">
      <div class="service__contents__title">Service</div>
      <div class="service__contents__main">
        <div class="service__contents__main__left">
          <div class="service__contents__main__left__squwea" />
          <v-img src="../assets/img/top-service.png" class="service__contents__main__left__image" />
        </div>
        <div class="service__contents__main__right">
          <div class="service__contents__main__right__text">
            <div class="service__contents__main__right__text__title">SES</div>
            <div class="service__contents__main__right__text__subTitle">システムエンジニアリングサービス</div>
            <div class="service__contents__main__right__text__massage">
              ご要望に沿った技術を持つ<br />技術者をご提案致します。
            </div>
          </div>
          <div @click="onClickMoreButton()" class="service__contents__main__right__text__more-button">
            <MoreButton class="service__contents__main__right__text__more-button--button" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreButton from './MoreButton.vue';

export default {
  name: 'ServiceParts',

  components: {
    MoreButton,
  },
  methods: {
    onClickMoreButton() {
      this.$router.push('/service');
    },
  },
};
</script>

<style lang="scss" scoped>
.service {
  &__contents {
    margin: 5% auto 10%;

    @media screen and (min-width: 960px) {
      margin: 0 auto 10%;
    }

    &__title {
      font-size: min(5vw, 67px);
      text-align: center;
    }

    &__main {
      margin: 5% auto 0;
      display: flex;
      gap: 5%;
      width: 65vw;
      min-width: 300px;
      border: 2px solid #b4b4b4;

      @media screen and (min-width: 640px) {
        width: 60vw;
      }

      @media screen and (min-width: 960px) {
        width: 50vw;
        min-width: 340px;
        font-size: min(2vw, 18px);
      }

      &__left {
        padding: 10% 0;
        width: 40%;

        @media screen and (min-width: 640px) {
          width: 45%;
          padding: 10% 0;
        }

        &__image {
          width: 30vw;
          min-width: 100px;
          max-width: 250px;
          margin: -10% 0 0 -14%;

          @media screen and (min-width: 960px) {
            width: 25vw;
            min-width: 250px;
            max-width: 800px;
          }
        }

        &__squwea {
          position: relative;
          z-index: 1;
          background: #dedede;
          width: 10vw;
          height: 10vw;
          margin: -30% auto auto -25%;

          @media screen and (min-width: 960px) {
            width: 7vw;
            height: 7vw;
            max-width: 180px;
            max-height: 180px;
          }
        }
      }
      &__right {
        margin: auto auto;
        padding-right: 5%;
        text-align: center;

        &__text {
          margin: 10% auto;
          &__title {
            font-size: min(4vw, 50px);
          }
          &__subTitle {
            border-top: solid #dcdcdf;
            padding-top: 3%;
            font-size: min(0.1rem, 4px);
            color: #383838e6;
            white-space: nowrap;

            @media screen and (min-width: 960px) {
              font-size: min(1.2vw, 25px);
            }
          }

          &__massage {
            margin: 7% auto 15%;
            font-size: min(2vw, 22px);
            white-space: nowrap;

            @media screen and (min-width: 960px) {
              margin: 15% auto 20%;
              font-size: min(1.6vw, 100px);
            }
          }

          &__more-button {
            &--button {
              margin: 0 auto 5%;

              @media screen and (min-width: 960px) {
                margin: 0 auto 10%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
