<template>
  <div class="footer">
    <div class="footer__contents">
      <div class="footer__contents__center">
        <div class="footer__contents__center__company">
          <div class="footer__contents__center__company__title">Company</div>
          <a @click="onClickCompany()">代表メッセージ</a>
          <a @click="onClickCompany()">会社概要</a>
          <!-- <a @click="onClickNews()">News</a> -->
        </div>
      </div>
      <div class="footer__contents__right">
        <div class="footer__contents__right__service">
          <div class="footer__contents__right__service__title">Service</div>
          <a @click="onClickService()">SES</a>
        </div>
        <div class="footer__contents__right__partner">
          <div class="footer__contents__right__partner__title">Partner</div>
          <a @click="onClickPartner()">パートナー企業募集</a>
        </div>
        <div class="footer__contents__right__recruit">
          <div class="footer__contents__right__recruit__title">Recruit</div>
          <a @click="onClickRecruit()">採用情報</a>
        </div>
        <div class="footer__contents__right__cosmo">
          <div class="footer__contents__right__cosmo__title">Group</div>
          <a href="https://www.cosmo-japan.net/" target="_blank">株式会社コスモジャパン</a>
        </div>
      </div>
    </div>
    <div class="footer__copyright">
      <div class="footer__copyright__policy">
        <a @click="onClickPrivacyPolicy()" class="footer__copyright__policy--privacyPolicy">PrivacyPolicy</a>
        <a @click="onClickSitePolicy()" class="footer__copyright__policy--sitePolicy">SitePolicy</a>
      </div>
      <a href="" class="footer__copyright__text">©︎ 2022 Mehrdius Band Kosmologie Co., Ltd. All Rights Reserved.</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterArea',
  components: {},
  methods: {
    onClickTop() {
      if (this.$route.path === '/') {
        return;
      }
      this.$router.push('/');
    },
    onClickCompany() {
      if (this.$route.path === '/company') {
        return;
      }
      this.$router.push('/company');
    },
    // onClickNews() {
    //   if (this.$route.path === '/news') {
    //     return;
    //   }
    //   this.$router.push('/news');
    // },
    onClickService() {
      if (this.$route.path === '/service') {
        return;
      }
      this.$router.push('/service');
    },
    onClickPartner() {
      if (this.$route.path === '/partner') {
        return;
      }
      this.$router.push('/partner');
    },
    onClickRecruit() {
      if (this.$route.path === '/recruit') {
        return;
      }
      this.$router.push('/recruit');
    },
    onClickPrivacyPolicy() {
      if (this.$route.path === '/privacy-policy') {
        return;
      }
      this.$router.push('/privacy-policy');
    },
    onClickSitePolicy() {
      if (this.$route.path === '/site-policy') {
        return;
      }
      this.$router.push('/site-policy');
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #91999d;
  text-decoration: none;
  color: #fff;
  margin: 0 auto;
  font-size: min(3.5vw, 22px);
  @media screen and (min-width: 640px) {
    font-size: min(3.5vw, 16px);
  }

  &__contents {
    width: 85%;
    padding-top: 3%;
    margin: auto;
    display: flex;
    gap: 5%;
    justify-content: center;

    @media screen and (min-width: 640px) {
      width: 70%;
      justify-content: flex-end;
    }

    &__center {
      width: (100%)/2;

      @media screen and (min-width: 640px) {
        width: (100%)/3;
      }

      &__company {
        display: flex;
        flex-direction: column;
        &__title {
          border-bottom: solid;
          color: #fff;
          line-height: 40px;
          font-size: min(4vw, 28px);

          @media screen and (min-width: 640px) {
            font-size: min(2.3vw, 28px);
          }
        }

        a {
          display: inline-block;
          margin: 10% 0 0 auto;
          text-decoration: none;
          color: #fff;
          line-height: 30px;
          &:hover {
            color: #16203c;
          }

          @media screen and (min-width: 640px) {
            line-height: 20px;
          }
        }
      }
    }
    &__right {
      width: (100%)/2;

      @media screen and (min-width: 640px) {
        width: (100%)/3;
      }

      &__service,
      &__partner,
      &__company,
      &__recruit,
      &__cosmo {
        display: flex;
        flex-direction: column;
        &__title {
          border-bottom: solid;
          color: #fff;
          line-height: 40px;
          font-size: min(4vw, 28px);

          @media screen and (min-width: 640px) {
            font-size: min(2.3vw, 28px);
          }
        }

        a {
          display: inline-block;
          margin: 10% 0 0 auto;
          text-decoration: none;
          color: #fff;
          line-height: 30px;
          &:hover {
            color: #16203c;
          }

          @media screen and (min-width: 640px) {
            line-height: 20px;
          }
        }
      }
    }
  }

  &__copyright {
    margin: 5% auto 0;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    color: #fff;
    &__policy {
      width: 30%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      gap: 10%;
      &--privacyPolicy {
        color: #fff;
        &:hover {
          color: #16203c;
        }
      }

      &--sitePolicy {
        color: #fff;
        &:hover {
          color: #16203c;
        }
      }
    }
    &__text {
      margin: 0 auto 5%;
      text-decoration: none;
      color: #fff;
      display: block;
      display: inline-block;
      &:hover {
        color: #16203c;
      }
    }
    width: 60%;
  }
}
</style>

<!-- #16203c -->
