<template>
  <div>
    <div class="header" :class="getClassScroll()">
      <div class="header__contents">
        <div class="header__contents__logo">
          <v-img
            class="header__contents__logo--image"
            src="../assets/img/logo_mbk_no-text.png"
            @click="onClickTop()"
            width="9.5vw"
            max-width="80px"
            potision="center center"
          />
        </div>
        <div class="header__contents__sp" v-if="isScreenSmAndDown">
          <div class="header__contents__sp-button" :class="{ open: isOpening }" @click="isOpening = !isOpening">
            <div :class="getClassScroll()" />
            <div :class="getClassScroll()" />
          </div>
          <div class="header__contents__sp-background" :class="{ open: isOpening }" @click="isOpening = false"></div>
          <nav class="header__contents__sp-body" :class="{ open: isOpening }">
            <ol class="header__contents__sp-body-list">
              <li><a @click="onClickTop(), (isOpening = false)">TOP</a></li>
              <hr />
              <!-- <li><a @click="onClickNews(), (isOpening = false)">News</a></li>
                <hr /> -->
              <li><a @click="onClickService(), (isOpening = false)">Service</a></li>
              <hr />
              <li><a @click="onClickPartner(), (isOpening = false)">Partner</a></li>
              <hr />
              <li><a @click="onClickRecruit(), (isOpening = false)">Recruit</a></li>
              <hr />
              <li><a @click="onClickCompany(), (isOpening = false)">Company</a></li>
            </ol>
            <a href="" @click="isOpening = false"></a>
          </nav>
        </div>
        <div class="header__contents__pc" v-else>
          <ol class="header__contents__pc__list">
            <li><a :class="getClassScroll()" @click="onClickTop(), (isOpening = false)">Top</a></li>
            <li><a :class="getClassScroll()" @click="onClickCompany(), (isOpening = false)">Company</a></li>
            <!-- <li><a :class="getClassScroll()" @click="onClickNews(), (isOpening = false)">News</a></li> -->
            <li><a :class="getClassScroll()" @click="onClickService(), (isOpening = false)">Service</a></li>
            <li><a :class="getClassScroll()" @click="onClickPartner(), (isOpening = false)">Partner</a></li>
            <li><a :class="getClassScroll()" @click="onClickRecruit(), (isOpening = false)">Recruit</a></li>

            <li>
              <a
                class="header__contents__pc__list--contact"
                href="https://forms.gle/1NvRN4vpWAkaiQMd9"
                target="_blank"
                @click="isOpening = false"
                >Contact</a
              >
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderMenu',

  components: {},
  computed: {
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  data() {
    return {
      isOpening: false,
      scrollY: 0,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.getScroll);
  },
  methods: {
    onClickTop() {
      if (this.$route.path === '/') {
        //↓コメントアウトしたものとlocationのどちらも画面上リロードされている挙動が見えるのですが、今回の場合どちらを使用するのが正しいのでしょうか。。
        location.reload();
        // this.$router.go({ path: this.$router.currentRoute.path, force: true });
        return;
      }
      this.$router.push('/');
    },
    onClickCompany() {
      if (this.$route.path === '/company') {
        return;
      }
      this.$router.push('/company');
    },
    onClickNews() {
      if (this.$route.path === '/news') {
        return;
      }
      this.$router.push('/news');
    },
    onClickService() {
      if (this.$route.path === '/service') {
        return;
      }
      this.$router.push('/service');
    },
    onClickPartner() {
      if (this.$route.path === '/partner') {
        return;
      }
      this.$router.push('/partner');
    },
    onClickRecruit() {
      if (this.$route.path === '/recruit') {
        return;
      }
      this.$router.push('/recruit');
    },
    onClickContact() {
      if (this.$route.path === '/contact') {
        return;
      }
      this.$router.push('/contact');
    },
    getScroll() {
      this.scrollY = window.scrollY;
    },
    getClassScroll() {
      return [
        { scrollTop: this.scrollY > 625 && this.$route.path === '/' },
        { scrollContents: this.scrollY > 350 && this.$route.path != '/' },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  .scrollTop,
  .scrollContents {
    height: 8.5%;
    background: rgba(255, 255, 255, 1);
  }

  &__contents {
    width: 100%;
    height: 10%;
    min-height: 80px;
    max-height: 80px;
    display: flex;
    font-size: min(11vw, 18px);
    margin: 0 auto;

    @media screen and (min-width: 960px) {
      width: 90%;
    }

    &__logo {
      background: rgba(255, 255, 255, 1);
      border-radius: 50%;
      margin: -7vw auto auto -7vw;
      width: 24vw;
      height: 24vw;
      max-width: 130px;
      max-height: 130px;
      min-width: 105px;
      min-height: 105px;
      cursor: pointer;

      @media screen and (min-width: 640px) {
        margin: -7vw auto auto -7vw;
        width: 20vw;
        height: 20vw;
        max-width: 180px;
        max-height: 180px;
      }

      @media screen and (min-width: 960px) {
        margin: 1.5% auto;
        width: 12vw;
        height: 12vw;
        min-width: 85px;
        min-height: 85px;
        max-width: 97px;
        max-height: 97px;
      }
      &--image {
        min-width: 65px;
        margin: 7.5vw 3.5vw auto auto;

        @media screen and (min-width: 640px) {
          margin: 7.5vw 3vw auto auto;
        }

        @media screen and (min-width: 960px) {
          margin: 0.3vw auto auto 0.3vw;
          min-width: 85px;
        }
      }
    }

    &__contact {
      //  position: fixed;

      background: rgb(0, 0, 0);
      color: #fff;
      opacity: 0.5;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 10vw;
      min-width: 75px;
      max-width: 95px;
      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }

      @media screen and (min-width: 960px) {
        margin: 0 auto auto 0;
        width: 13%;
        max-width: 140px;
        height: 70%;
        min-height: 56px;
      }
    }

    &__contact {
      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }

    &__sp {
      background: blue;

      &-button {
        position: fixed;
        width: 8vh;
        height: 8vh;
        top: 1.5vh;
        right: 2vh;
        margin: 0 0 0 auto;
        z-index: 101;
        transition: all 0.5s;
        &:button:hover {
          background-color: rgba(0, 0, 0, 0.7);
        }

        div {
          background: #fff;
          width: 75%;
          height: 10%;
          border-radius: 10px;
          margin: 20% auto 0;
          transition: all 0.5s;
        }
        .scrollTop,
        .scrollContents {
          background: #374156;
        }

        &.open {
          div:nth-of-type(1) {
            transform: translateY(1vh) rotate(45deg);
            background: #374156;
          }
          div:nth-of-type(2) {
            transform: translateY(-1.28vh) rotate(-225deg);
            background: #374156;
          }
        }
      }

      &-background {
        position: fixed;
        display: none;
        background: black;
        width: 40%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.5;
        z-index: 100;

        &.open {
          display: block;
        }
      }

      &-body {
        position: fixed;
        display: none;
        width: 60%;
        height: 100vh;
        top: 0;
        right: 0;
        text-align: center;
        background-color: #ffffff;
        background-image: linear-gradient(
          -60deg,
          rgb(233, 233, 233) 45%,
          rgb(207, 219, 226) 45% 80%,
          rgb(243, 245, 242) 80%
        );
        opacity: 1;
        transform: translateX(100%);
        z-index: 100;

        @media screen and (min-width: 960px) {
          display: inline-block;
        }

        hr {
          background-color: rgb(232, 232, 232);
          width: 80%;
          height: 2px;
          margin: 0 auto;
          border: none;
        }

        &-list {
          height: 100%;
          margin: 5vh auto;
          list-style: none;

          a {
            display: inline-block;
            text-decoration: none;
            font: bold, 4vh, 'Hiragino Mincho ProN', 'ヒラギノ明朝 ProN', sans-serif;
            color: #2b2b2b;
            margin: 5vh auto;
          }
        }

        &.open {
          display: block;
          transform: translateX(0%);
        }
      }
    }
    &__pc {
      display: flex;
      width: 85%;
      max-width: 1800px;
      font-size: min(1.7vw, 28px);
      height: 70%;
      margin: 0 0 auto auto;
      font-weight: 900;

      &__list {
        margin: auto;
        display: flex;
        margin: auto 0 auto auto;
        width: 90%;
        max-width: 1000px;
        li {
          list-style: none;
          margin: auto;

          &:last-child {
            a {
              background: rgba(0, 0, 0, 0.45);
              display: flex;
              width: 12vw;
              height: 6vw;
              max-width: 140px;
              max-height: 60px;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              &:hover {
                display: flex;
                color: rgb(255, 255, 255);
                background: rgba(0, 0, 0, 0.7);
              }
            }
          }
          a {
            color: #fff;
            text-decoration: none;
            &:hover {
              color: #939393;
            }
          }

          .scrollTop,
          .scrollContents {
            background: none;
            color: #4a555e;
          }
        }
      }

      &__contact {
        background: rgb(0, 0, 0);
        color: #fff;
        opacity: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10vw;
        min-width: 75px;
        max-width: 95px;
        &:hover {
          background-color: rgba(0, 0, 0, 0.7);
        }

        @media screen and (min-width: 960px) {
          margin: 0 0 auto auto;
          width: 13%;
          max-width: 140px;
          height: 70%;
          min-height: 56px;
        }
      }
    }
  }
}
</style>
