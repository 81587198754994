<template>
  <div id="site-policy" class="policy common">
    <div class="policy-background">
      <ContentsTag />
      <div class="policy-title">Privacy Policy</div>
      <div class="area">
        <div class="area">
          <h2>当サイトの利用について</h2>
          <p>
            メディウスバンドコスモロギー株式会社（以下、「当社」という。）は、IT事業、システムインテグレーション事業を営むにあたり、大量の個人情報を様々な形で取り扱っております。
          </p>
          <p>
            事業活動を通じてお客様から取得する個人情報及び当社社員の個人情報（以下、「個人情報」という。）は、当社にとって大変重要な情報資産であり、その個人情報を確実に保護することは、当社の重要な社会的責務と認識しております。
          </p>
          <p>
            よって当社は、個人情報保護に関する法令、国が定める指針その他の規範を遵守し、個人情報を正確かつ誠実に取り扱うため、以下に掲げた事項を常に念頭に置き、お客さまの個人情報保護に万全を尽くしてまいります。
          </p>
          <br />
          <h3>個人情報保護に関する方針</h3>
          <ul class="policy-contents-list">
            <li>
              個人情報保護の重要性を社員一同が認識し、当社の事業目的を遂行する範囲内で、適法かつ公正な手段によって、これを取得、利用及び提供します。また、個人情報の目的外利用については一切これを行いません。目的外利用の必要が生じた場合、新たな利用目的の再同意を得た上で利用します。
            </li>
            <li>
              個人情報に関する法令、国が定める指針その他の規範（以下、「法令等」という。）及び個人情報保護マネジメントシステムを遵守し、個人情報を誠実に取り扱います。また、法令等を常に把握することに努め、当社事業に従事する従業員（以下、「従業員」という。）、取引先に周知し順守いたします。
            </li>
            <li>
              個人情報の漏えい、滅失又はき損等の危険に対し、技術面及び組織面において合理的な安全対策、防止措置を講じます。また、定期的な点検を実施し、発見された違反、事件及び事故に対して、速やかにこれを是正するとともに、弱点に対する予防措置を実施します。従業員、取引先には安全に関する教育を徹底いたします。
            </li>
            <li>
              弊社の個人情報の取扱い及び個人情報保護マネジメントシステムに関する苦情及び相談については、受け付け次第、適切、かつ、迅速な対応をいたします。また、その体制、手順については確立・整備をおこない、常に適切に対応出来る体制を維持していきます。
            </li>
            <li>
              個人情報を適正に利用し、またその保護を徹底するために、内部規程順守状況を監視及び監査し、違反、事件、事故及び弱点の発見に努め、経営者による見直しを実施します。これを管理策及び内部規程に反映することで継続的に個人情報保護マネジメントシステムを見直し、改善していきます。なお、改善については法令等及びJISQ15001に準拠いたします。
            </li>
          </ul>
          <br />
          <h3>個人情報の取り扱いについて</h3>
          <ul class="policy-contents-list">
            <li>個人情報の取得・利用目的について</li>
            <p>弊社は、取得した個人情報を以下の目的のために利用させていただきます。</p>
            <ul class="policy-contents-list">
              <li>ユーザーに関する個人情報</li>
              <ul class="policy-contents-list-sub">
                <li>サービスおよびイベントに関する案内の送付</li>
                <li>カスタマーサポートサービスの提供</li>
                <li>問い合わせ及び相談への対応</li>
                <li>ゲーム開発、アンケート調査実施、モニター実施</li>
                <li>契約の履行</li>
              </ul>
              <li>取引先をはじめとする他社の役員および従業員等の方に関する個人情報</li>
              <ul class="policy-contents-list-sub">
                <li>業務上必要となる連絡、取引先情報管理、および支払・収入処理</li>
              </ul>
              <li>取引先から委託を受けた個人情報</li>
              <ul class="policy-contents-list-sub">
                <li>委託業務に関する契約の履行</li>
              </ul>
              <li>採用応募者に関する個人情報</li>
              <ul class="policy-contents-list-sub">
                <li>採用に際しての企業情報及び採用情報等の案内のため</li>
                <li>採用選考に際して必要となる申込受付、実施および連絡等のため</li>
                <li>採用に際して必要となる通知（配属先の決定、内定式及び入社式等の各種イベントの案内）のため</li>
              </ul>
            </ul>
            <li>個人情報の委託について</li>
            <p>
              弊社は、上記利用目的の範囲内で、個人情報の取扱いを外部に委託する場合があります。その場合には、個人情報の適正な管理体制を備えている機関のみを選定し、委託先に対し必要かつ適切な監督を行います。
            </p>
            <li>個人情報の第三者提供について</li>
            <p>
              弊社は、取得した個人情報を適切に管理し、あらかじめご本人の同意を得ることなく個人情報を第三者に提供することはいたしません。
            </p>
            <p>ただし、以下のいずれかに該当する場合は、この限りではありません。</p>
            <ul class="policy-contents-list-sub">
              <li>法令に基づき開示・提供を求められた場合</li>
              <li>
                個人情報の取扱に関する業務の全部または一部を委託する場合（但しこの場合、当社は委託先との間で個人情報保護に関する契約を締結する等、委託先の適切な監督に努めます。）
              </li>
              <li>統計的なデータなどご本人を識別することができない状態で開示・提供する場合</li>
              <li>人の生命、身体または財産の保護のために必要な場合であって、ご本人の同意を得ることが困難である場合</li>
              <li>
                国または地方公共団体等が公的な事務を実施するうえで、協力する必要がある場合であって、ご本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
              </li>
            </ul>
            <li>個人情報の開示について</li>
            <p>
              当社は、ご本人から、個人情報の開示を求められたときは、ご本人からの請求であることを確認の上で、本人に対し、遅滞なく開示します。
            </p>
            <p>
              ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
            </p>
            <ul class="policy-contents-list-sub">
              <li>本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
              <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
              <li>他の法令に違反することとなる場合</li>
            </ul>
            <li>個人情報提供の任意性</li>
            <p>お客様が個人情報の提供を希望されない場合、ご自身の判断により、提供を拒否することができます。</p>
            <p>
              その場合、弊社サービスのご利用・弊社とのお取引・従業員採用選考活動・お問い合わせの回答等において、不利益な結果が生じる場合がございます。
            </p>
            <li>ご本人が容易に認識できない方法によって個人情報を取得する場合の事項</li>
            <p>ご本人が容易に認識できない方法によって個人情報を取得することはありません。</p>
          </ul>
          <br />
          <h3>お問い合わせ</h3>
          <p>個人情報に関するお問い合わせは下記にご連絡ください。</p>
          <p>privacy@m-b-kosmo.net</p>
          <br />
          <div class="rev">
            <p>制定：2022年7月30日</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentsTag from '../parts/ContentsTag.vue';

export default {
  name: 'PrivacyPolicy',

  components: { ContentsTag },
};
</script>

<style lang="scss" scoped>
.policy {
  &-background {
    padding: 10% 0 10%;
    background-color: #ffffff;
    background-image: linear-gradient(
      -60deg,
      rgb(243, 243, 243) 25%,
      rgb(234, 239, 242) 25% 80%,
      rgb(242, 247, 248) 80%
    );
    @media screen and (min-width: 960px) {
      padding: 5% 0 10%;
    }
  }

  &-title {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #696969;
    font-size: min(10vw, 40px);
    // font-size: 6vw;
    font-weight: bold;
    margin: 10vh 0 5%;
  }

  // ol{
  //   list-style:none;
  // }
}

.area {
  background-color: #ffffff;
  justify-content: center;
  padding: 5%;
  margin: 0% auto;
  border-radius: 10px;
  max-width: 900px;
  width: 80%;
  font-size: min(3.5vw, 18px);
  @media screen and (min-width: 960px) {
    width: 100%;
  }

  h2 {
    font-size: min(5vw, 13px);
    font-weight: bold;
    margin: 15px 0;
    @media screen and (min-width: 960px) {
      font-size: min(5vw, 18px);
    }
  }

  h3 {
    font-size: min(3vw, 15px);
    margin: 15px 0;
    @media screen and (min-width: 960px) {
      font-size: min(3vw, 15px);
    }
  }

  p {
    font-size: min(3vw, 15px);
    @media screen and (min-width: 960px) {
      font-size: min(2vw, 15px);
    }
  }
}
</style>
