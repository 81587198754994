<template>
  <div class="top">
    <div class="top__contents">
      <div class="top__contents--massage" v-if="isScreenSmAndDown">
        <div class="top__contents--massage--text">
          <p>
            WEBシステム開発、第三者検証/品質保証<br />インフラ/サーバー構築・運用・保守等<br />最適な技術でお客様のビジネス発展へ貢献致します。
          </p>
        </div>
      </div>
      <div
        v-if="isScreenSmAndDown"
        class="top__contents__acodion--service"
        :class="getClassOpen()"
        @click="isServiceOpen()"
      >
        Service
      </div>
      <ServiceParts v-if="serviceOpen || isScreenMdAndUp" />
      <!-- <div v-if="isScreenSmAndDown" class="top__contents__acodion--news" :class="getClassOpen()" @click="isNewsOpen()">
        News
      </div>
      <NewsParts v-if="newsOpen || isScreenMdAndUp" />
    -->
      <div
        v-if="isScreenSmAndDown"
        class="top__contents__acodion--partner"
        :class="getClassOpen()"
        @click="isPartnerOpen()"
      >
        Partner
      </div>
      <PartnerParts v-if="partnerOpen || isScreenMdAndUp" />
      <div
        v-if="isScreenSmAndDown"
        class="top__contents__acodion--recruit"
        :class="getClassOpen()"
        @click="isRecruitOpen()"
      >
        Recruit
      </div>
      <RecruitParts v-if="recruitOpen || isScreenMdAndUp" />
      <div
        v-if="isScreenSmAndDown"
        class="top__contents__acodion--company"
        :class="getClassOpen()"
        @click="isCompanyOpen()"
      >
        Company
      </div>
      <CompanyParts v-if="companyOpen || isScreenMdAndUp" />
    </div>
  </div>
</template>

<script>
import ServiceParts from '../parts/Top-service.vue';
// import NewsParts from '../parts/Top-news.vue';
import RecruitParts from '../parts/Top-recruit.vue';
import PartnerParts from '../parts/Top-partner.vue';
import CompanyParts from '../parts/Top-company.vue';

export default {
  name: 'TopView',

  components: {
    ServiceParts,
    // NewsParts,
    RecruitParts,
    PartnerParts,
    CompanyParts,
  },
  computed: {
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isScreenMdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },

  data: () => ({
    serviceOpen: false,
    newsOpen: false,
    recruitOpen: false,
    partnerOpen: false,
    companyOpen: false,
  }),

  methods: {
    isServiceOpen() {
      this.serviceOpen = !this.serviceOpen;
    },
    isNewsOpen() {
      this.newsOpen = !this.newsOpen;
    },
    isRecruitOpen() {
      this.recruitOpen = !this.recruitOpen;
    },
    isPartnerOpen() {
      this.partnerOpen = !this.partnerOpen;
    },
    isCompanyOpen() {
      this.companyOpen = !this.companyOpen;
    },
    getClassOpen() {
      return [
        { 'rotate-service': this.serviceOpen },
        { 'rotate-news': this.newsOpen },
        { 'rotate-recruit': this.recruitOpen },
        { 'rotate-partner': this.partnerOpen },
        { 'rotate-company': this.companyOpen },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  background-image: linear-gradient(
    165deg,
    transparent 13%,
    rgba(142, 172, 200, 0.3) 13%,
    13.5%,
    rgba(243, 245, 242, 0.1) 13.5% 50%,
    rgba(243, 245, 242, 0.7) 50% 50.5%,
    transparent 50.5% 60%,
    rgba(243, 245, 242, 0.7) 60% 60.5%,
    transparent 60.5% 75%,
    rgba(233, 233, 233, 0.2) 75%,
    75.2%,
    rgba(233, 233, 233, 0.1) 75.5%
  );

  &__contents {
    margin: 15% auto auto;
    @media screen and (min-width: 960px) {
      margin: 5% auto auto;
    }
    &--massage {
      position: relative;
      height: 45vw;

      &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 23vw 38vw 23vw;
        border-color: transparent transparent rgba(116, 149, 173, 0.1) transparent;
        transform: rotate(105deg);
        top: 3%;
        right: 6%;
      }

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 23vw 38vw 23vw;
        border-color: transparent transparent rgba(120, 163, 195, 0.1) transparent;
        transform: rotate(75deg);
        top: -7%;
        left: 17%;
      }

      p {
        padding-top: 8vw;
        padding-left: 13%;
        font-size: min(3.5vw, 30px);
        font-weight: bold;
        line-height: 1.8rem;
        color: #696969;
      }
    }

    &__acodion--service,
    &__acodion--news,
    &__acodion--recruit,
    &__acodion--partner,
    &__acodion--company {
      position: relative;
      overflow: hidden;
      padding-left: calc(8vw + 10px);
      font-size: min(5vw, 30px);
      min-height: 8vw;

      @media screen and (min-width: 640px) {
        font-size: min(8vw, 40px);
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        border-right: 8vw solid transparent;
      }

      &::after {
        content: '';
        position: absolute;
        width: 2.5vw;
        height: 2.5vw;
        border-top: solid 2px #999999;
        border-right: solid 2px #999999;
        transform: rotate(135deg);
        transition: 0.5s;
        top: 25%;
        right: 4vw;
      }
    }

    &__acodion--service {
      border-bottom: 2px solid #e4e6ed;

      &::before {
        border-bottom: 8vw solid #e4e6ed;
      }
      &.rotate-service {
        &::after {
          transform: rotate(675deg);
          transition: 0.5s;
        }
      }
    }

    &__acodion--news {
      border-bottom: 2px solid #dedede;

      &::before {
        border-bottom: 8vw solid #dedede;
      }
      &.rotate-news {
        &::after {
          transform: rotate(675deg);
          transition: 0.5s;
        }
      }
    }

    &__acodion--recruit {
      border-bottom: 2px solid #c4c4c7;

      &::before {
        border-bottom: 8vw solid #c4c4c7;
      }
      &.rotate-recruit {
        &::after {
          transform: rotate(675deg);
          transition: 0.5s;
        }
      }
    }

    &__acodion--partner {
      border-bottom: 2px solid #c8ccd5;

      &::before {
        border-bottom: 8vw solid #c8ccd5;
      }
      &.rotate-partner {
        &::after {
          transform: rotate(675deg);
          transition: 0.5s;
        }
      }
    }

    &__acodion--company {
      border-bottom: 2px solid #f2f2f2;

      &::before {
        border-bottom: 8vw solid #f2f2f2;
      }
      &.rotate-company {
        &::after {
          transform: rotate(675deg);
          transition: 0.5s;
        }
      }
    }
  }

  .isOpen {
  }

  background-attachment: fixed;
  &__service {
    // margin: 1% auto 0;
  }
  &__news {
    // background: pink;
    // background: rgb(255, 226, 231);
  }
  &__recruit {
    // background: rgb(239, 221, 224);
  }
  &__partner {
    // background: rgb(255, 243, 245);
  }
  &__company {
    // background: rgb(193, 103, 118);
  }
}
</style>
