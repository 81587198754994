<template>
  <v-app>
    <v-main>
      <HeaderMenu class="header" />
      <HeaderImage :getRoute="getRoute" />
      <transition mode="out-in">
        <router-view />
      </transition>
      <FooterArea />
    </v-main>
  </v-app>
</template>

<script>
import HeaderMenu from './components/Header.vue';
import HeaderImage from './components/HeaderImage.vue';
import FooterArea from './components/Footer.vue';

export default {
  name: 'App',

  components: {
    HeaderMenu,
    HeaderImage,
    FooterArea,
  },

  data: () => ({
    //
  }),
  computed: {
    getRoute() {
      return this.$route.path;
    },
    imageText() {
      return this.getRouteImage;
    },
  },
  methods: {
    getRouteImage() {
      let route = this.getRoute;
      return [
        { top: route === '/' },
        { company: route === '/company' },
        { service: route === '/service' },
        { partner: route === '/partner' },
        { news: route === '/news' },
        { recruit: route === '/recruit' },
        { privacy: route === '/pricacy' },
        { site: route === '/site' },
      ];
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: 'Hiragino Mincho ProN', 'ヒラギノ明朝 ProN', sans-serif;
  color: #6f6f6f;
}
.header {
  position: fixed;
  width: 100vw;
  z-index: 1000;
}

.v-enter,
.v-leave,
.v-leave-active,
.v-leave-to {
  opacity: 0;
}

.v-enter-from,
.v-enter-to {
  opacity: 1;
  transition: opacity 1.1s ease;
}
</style>
