<template>
  <div class="recruit">
    <div class="recruit__contents">
      <div class="recruit__contents__image">
        <v-img src="../assets/img/top-recruit.png" />
      </div>
      <div class="recruit__contents__text">
        <div class="recruit__contents__text__title">Recruit</div>
        <div class="recruit__contents__text__massage">
          私たちと共に働く、<br class="br-sp" />仲間を募集しております。
        </div>
        <div @click="onClickMoreButton()" class="recruit__contents__text__more-button">
          <MoreButton class="recruit__contents__text__more-button--button" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreButton from './MoreButton.vue';

export default {
  name: 'RecruitParts',

  components: {
    MoreButton,
  },
  methods: {
    onClickMoreButton() {
      this.$router.push('/recruit');
    },
  },
};
</script>

<style lang="scss" scoped>
.recruit {
  &__contents {
    width: 60%;
    margin: 5% auto;
    display: flex;

    @media screen and (min-width: 960px) {
      margin: 5% auto;
      height: 40vw;
    }

    &__image {
      width: 60%;
      margin: auto 0 auto auto;

      @media screen and (min-width: 940px) {
        width: 50%;
        margin: auto 0 auto auto;
      }
    }

    &__text {
      width: 50%;
      margin: 15% 3% auto;
      text-align: center;

      @media screen and (min-width: 940px) {
        margin: 15% 0 auto;
      }

      &__title {
        font-size: min(5vw, 67px);
      }

      &__massage {
        margin: 7% auto 15%;
        font-size: min(2vw, 22px);
        white-space: nowrap;
        word-break: break-word;

        @media screen and (min-width: 640px) {
          margin: 10% auto 15%;
          font-size: min(1.6vw, 100px);
          .br-sp {
            display: none;
          }
        }
      }

      &__more-button {
        &--button {
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
