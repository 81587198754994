<template>
  <div class="partner">
    <div class="partner__contents">
      <div class="partner__contents__tag">
        <ContentsTag />
      </div>
      <div class="partner__contents--title"><p>SESパートナーご協業企業様募集</p></div>
      <div class="partner__contents__main">
        <!-- <div class="partner__contents__main"> -->
        <v-img class="partner__contents__main__image" src="../assets/img/partner-hand.png">
          <div class="partner__contents__main__image__text-area">
            <div class="partner__contents__main__image__text-area--sub-title">
              <p>ご協業について</p>
            </div>
            <div class="partner__contents__main__image__text-area--text">
              <p>
                SESの領域でパートナー企業様を募集しております。<br /><br />
                当社では繋がり、関係構築を大切にしておりますので、継続的にご協力関係を築ければと考えております。<br /><br />
                案件のご紹介・人材のご紹介の両面でご協力させて頂きます。<br />
                ご興味をお持ちいただけた企業様がいらっしゃいましたら、 是非情報交換をさせて頂けましたら幸いです。<br /><br />
                心よりご連絡をお待ちいたしております。
              </p>
            </div>
            <div class="partner__contents__main__image__text-area--button-area">
              <v-btn
                class="partner__contents__main__image__text-area--button-area--button"
                href="https://forms.gle/1NvRN4vpWAkaiQMd9"
                target="_blank"
                outlined
                height="4.5vw"
                width="32.5vw"
              >
                情報交換お申し込み</v-btn
              >
            </div>
          </div>
        </v-img>
        <!-- </dsiv> -->
      </div>
    </div>
  </div>
</template>

<script>
import ContentsTag from '../parts/ContentsTag.vue';

export default {
  name: 'PartnerView',
  data: () => ({
    //
    ButtonFlag: true,
  }),

  components: { ContentsTag },
  computed: {
    isScreenSmAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isScreenMdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.getScroll);
  },
  methods: {
    onClickScroll(refName) {
      if (this.isScreenMdAndUp) {
        const el = this.$refs[refName];
        el.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    },
    onClickMassage() {
      this.ButtonFlag = true;
    },
    onClickpartner() {
      this.ButtonFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.partner {
  &__contents {
    font-weight: bold;

    &--title {
      text-align: center;
      font-size: min(5vw, 28px);
      margin-bottom: 10%;

      @media screen and (min-width: 640px) {
      }

      @media screen and (min-width: 960px) {
        font-size: min(3vw, 54px);
        margin-bottom: 7%;
      }
    }

    &__main {
      margin: 0 auto;
      flex-wrap: wrap;

      &__image {
        width: 100%;

        @media screen and (min-width: 640px) {
          margin: 0 auto;
        }

        @media screen and (min-width: 960px) {
          margin: 5% 0 auto auto;
        }

        &__text-area {
          width: 70%;
          margin: 0 auto 15%;
          background: rgba(255, 255, 255, 0.8);
          box-shadow: -1px 2px 1px rgba(237, 238, 240, 0.8);
          padding: 5% 4% 2% 4%;

          @media screen and (min-width: 640px) {
          }

          @media screen and (min-width: 960px) {
            margin: 10% auto 2%;
            max-width: 1000px;
          }

          &--sub-title {
            width: 85%;
            margin: 0 auto 6%;
            border-bottom: solid 3px #b7b7b7;
            font-size: min(5vw, 25px);
            text-align: center;
          }

          &--text {
            width: 80%;
            margin: 0 auto;
            font-size: min(3vw, 20px);

            @media screen and (min-width: 640px) {
            }

            @media screen and (min-width: 960px) {
              max-width: 900px;
            }

            p {
              @media screen and (min-width: 640px) {
              }

              @media screen and (min-width: 960px) {
              }
            }
          }

          &--button-area {
            width: 100%;
            text-align: center;
            margin: 7% auto 5%;

            &--button {
              background: rgba(255, 255, 255, 0);
              color: rgb(122, 122, 122);
              border-radius: 1px;
              font-size: min(1vw, 18px);
              min-height: 30px;
              min-width: 150px;

              @media screen and (min-width: 640px) {
                font-size: min(1.8vw, 27px);
              }

              @media screen and (min-width: 960px) {
                max-height: 300px;
                max-width: 400px;
              }
            }
          }
        }

        &--name {
          width: 85%;
          padding: 5% 0 2% 50%;
          margin: 0% auto 20%;
          background: white;

          @media screen and (min-width: 640px) {
            max-width: 600px;
          }

          @media screen and (min-width: 960px) {
            width: 50%;
            background: none;
            margin: 0;
            padding: 0 0 3% 30%;
          }
        }
      }
    }
  }
}
</style>
