<template>
  <div id="site-policy" class="policy common">
    <div class="policy-background">
      <ContentsTag />
      <div class="policy-title">Site Policy</div>
      <div class="area">
        <div class="area-policy">
          <h2>当サイトの利用について</h2>
          <p>
            このWebサイト（以下「当サイト」と記）は、メディウスバンドコスモロギー株式会社（以下「当社」と記）が所有・運営しております。
            <br />当サイトのご利用にあたっては、以下のサイトポリシーをご理解・ご了承の上でご利用くださいませ。
          </p>
        </div>
        <div class="area">
          <h2>当サイトの著作権・商標について</h2>
          <p>
            当サイトに掲載されているコンテンツ（文字、写真、イラスト等）に関する著作権は、当社に帰属します。
            <br />私的使用目的等、法律で認められる場合を除いては、当サイトのコンテンツを許可なく複製・転用・販売・公開等をすることを禁じます。
            <br />当サイト上に掲載される個々の商標・ロゴマーク・商号に関する権利は、当社に帰属します。
            <br />法律で認められる範囲を除き、許可なく使用することはできません。
            <br />
          </p>
        </div>
        <div class="area">
          <h2>当サイトの免責事項</h2>
          <p>
            当サイトに掲載された情報に基づいて為された判断を原因としてトラブル・損失・損害が発生した場合、いかなる事があっても、当社は一切責任を負いません。
            <br />当サイトに掲載されたすべての情報、または一部の情報は、当社判断において予告なく変更・中断することがあります。また、当サイトの運営・公開は予告なく中止することがあります。
            <br />当サイトからの情報をご利用になられた、またご利用になれなかったことにより生じたいかなるトラブル・損失・損害についても当社は一切責任を負いません。
            <br />
          </p>
        </div>
        <div class="area">
          <h2>プライバシーポリシー</h2>
          <p>
            当社は、個人情報の重要性を鑑み、プライバシーポリシーを制定し、実行することにより個人情報保護に努めております。
            <br />準拠法及び管轄裁判所当サイトの利用について紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄とします。
            <br />当サイトの利用について紛争が生じた場合には、別段の定めのない限り、準拠法は日本法とします。
            <br />
          </p>
        </div>
        <div class="area">
          <h2>当サイトへのリンクについて</h2>
          <p>
            当サイトからのリンク・バナーによって他サイトへ移動できる場合があります。移動された先の他サイトは当社が運営するものではありません。したがって、その内容等に対して生じたいかなるトラブル・損失・損害についても当社は責任を負いかねますのでご了承ください。
            <br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContentsTag from '../parts/ContentsTag.vue';
export default {
  name: 'SitePolicy',

  components: { ContentsTag },
};
</script>

<style lang="scss" scoped>
.policy {
  &-background {
    padding: 10% 0 10%;
    background-color: #ffffff;
    background-image: linear-gradient(
      -60deg,
      rgb(243, 243, 243) 25%,
      rgb(234, 239, 242) 25% 80%,
      rgb(242, 247, 248) 80%
    );
    @media screen and (min-width: 960px) {
      padding: 5% 0 10%;
    }
  }
  &-title {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #696969;
    font-size: min(10vw, 40px);
    // font-size: 6vw;
    font-weight: bold;
    margin: 10vh 0 5%;
  }
}

.area {
  background-color: #ffffff;
  justify-content: center;
  padding: 5%;
  margin: 0% auto;
  border-radius: 10px;
  max-width: 900px;
  width: 80%;
  @media screen and (min-width: 960px) {
    width: 100%;
  }

  h2 {
    font-weight: bold;
    margin: 15px 0;
    font-size: min(5vw, 13px);
    @media screen and (min-width: 960px) {
      // width: 100%;
      font-size: min(5vw, 18px);
    }
  }

  h3 {
    font-size: min(3vw, 15px);
    margin: 15px 0;
  }

  p {
    font-size: min(2vw, 15px);
    // font-size: 2em;
  }
}
</style>
